import config from './config/config';

export default Object.freeze({
  ACCOUNT: '/secure/account/summary',
  AUTO_PAY_CANCEL_DONE: '/secure/profile/auto-pay/cancel-success',
  AUTO_PAY_CONFIG: '/secure/profile/auto-pay/setup',
  AUTO_PAY_CONFIRM: '/secure/profile/auto-pay/confirm',
  AUTO_PAY_DONE: '/secure/profile/auto-pay/success',
  AUTO_PAY_UPDATE_PAYMENT_PROFILE_CONFIRM:
    '/secure/profile/auto-pay/update-payment-method-confirm',
  AUTO_PAY_UPDATE_PAYMENT_PROFILE_DONE:
    '/secure/profile/auto-pay/update-payment-method-done',
  AUTO_PAY_UPDATE_DAY_DONE: '/secure/profile/auto-pay/update-day-done',
  AUTO_PAY: '/secure/profile/auto-pay',
  BILL_PAYMENT_ASSISTANCE:
    '/help/help-topics/bill-payment-assistance-residential',
  CANCEL_ONLINE_SERVICE: '/secure/profile/online-service/cancel',
  CANCEL_ONLINE_SERVICE_VERIFICATION:
    '/secure/profile/online-service/cancel/verify',
  CANCEL_ONLINE_SERVICE_CONFIRMATION:
    '/secure/profile/online-service/cancel/success',
  CHANGE_EMAIL: '/secure/profile/change-email',
  CHANGE_EMAIL_CONFIRMATION: '/change-email/confirmation',
  CHANGE_PASSWORD: '/secure/profile/change-password',
  CHANGE_PIN: '/secure/profile/change-pin',
  CLEAN_WIND_URL: '/energy-choices/renewable-power/green-future-block',
  CREATE_CUSTOM_GROUP: '/secure/account/create-custom-group',
  //'pge-service-area-map' was excluded from sitemap in onCreatePage function of gatesby-node.js
  //Path in EXCLUDED_PAGES_FROM_SITEMAP array must be updated incase the path changes in the future.
  PGE_SERVICE_AREA_MAP: '/pge-service-area-map',
  CLOSED_ACCOUNTS: '/secure/closed-accounts',
  CLOSED_ACCOUNTS_HISTORY: '/secure/closed-accounts/billing-payment-history',
  EQUAL_PAY: '/secure/payment/equal-pay',
  ERROR: '/error',
  FORGOT_PASSWORD_SPECIAL_HANDLING: '/auth/forgot-password-action-needed',
  FORGOT_PASSWORD: '/auth/forgot-password',
  GOOD_ENERGY_QUIZ_LINK: '/good-energy-quiz',
  GREEN_FUTURE_CHOICE_LANDING:
    '/residential/power-choices/renewable-power/green-source',
  GREEN_FUTURE_SOLAR_PDF:
    'https://assets.ctfassets.net/416ywc1laqmd/6yKkJpqNlre0dcWwcwN1y2/76f2d06f33407313d853ef784ac3fec8/product-content-label-green-future-solar-terms-conditions.pdf',
  TIME_OF_DAY_PDF:
    'https://assets.ctfassets.net/416ywc1laqmd/6prPmAxtKObZwhnADiyRCl/d80d54a7e525636a7b8e1fdc72a01703/Time-of-Day-Checklists-Summer.pdf',
  GREEN_FUTURE_SOLAR_RESIDENTIAL_ENROLL:
    '/green-future-solar-enrollment-residential/',
  GREEN_FUTURE_SOLAR: '/energy-choices/renewable-power/green-future-solar-home',
  GREEN_SOURCE_PDF: '/greenfuturecontent',
  GUEST_PAY_CONFIRMATION: '/guest-pay/verify',
  GUEST_PAY_SUCCESS: '/guest-pay/transaction-successful',
  GUEST_PAY: '/guest-pay',
  GUEST_PAYMENT_FORM: '/guest-pay/payment-information',
  GUEST_PAYMENT_METHOD: '/guest-pay/payment-method',
  HISTORICAL_USAGE_CONFIG: '/secure/account/historical-usage/config',
  HISTORICAL_USAGE_CONFIRM: '/secure/account/historical-usage/confirm',
  HISTORICAL_USAGE_DONE: '/secure/account/historical-usage/done',
  HISTORICAL_USAGE_LEGACY_URL: `${config.bizWebLegacyURL}/HistoricalUsageRelease/Auth`,
  HISTORICAL_USAGE: '/secure/account/historical-usage',
  HOME: '/',
  LEGAL_NOTICES: '/legal-notices',
  MANAGE_ACCOUNT_GROUPS: '/secure/account/manage-account-groups',
  MANAGE_ACCOUNTS_LANDING_SCREEN:
    '/secure/account/manage-account-groups/manage-accounts',
  ADD_GROUP_ACCOUNTS:
    '/secure/account/manage-account-groups/add-group-accounts',
  MANAGE_ACCOUNTS: '/secure/account/manage-accounts',
  MANAGE_ACCOUNTS_INELIGIBLE:
    '/secure/account/manage-account-groups/ineligible',
  MANAGE_ALERTS_CONFIRM: '/secure/account/manage-alerts/confirm',
  MANAGE_ALERTS_INELIGIBLE: '/secure/account/manage-alerts/ineligible',
  MANAGE_ALERTS_SUCCESS: '/secure/account/manage-alerts/success',
  MANAGE_ALERTS: '/secure/account/manage-alerts',
  MANAGE_BUSINESS_ACCOUNT: '/secure/account/manage-accounts/business',
  MANAGE_GROUPS_ACCOUNT:
    '/secure/account/manage-account-groups/add-account-group',
  MANAGE_RESIDENTIAL_ACCOUNT: '/secure/account/manage-accounts/residential',
  MEDICAL_CERTIFICATE: '/secure/programs/medical-certificate',
  MEDICAL_CERTIFICATE_CONFIRM:
    '/secure/programs/medical-certificate/confirmation',
  MEDICAL_CERTIFICATE_ENROLLED: '/secure/programs/medical-certificate/enrolled',
  MEDICAL_CERTIFICATE_INELIGIBLE:
    '/secure/programs/medical-certificate/ineligible',
  MOVE_SERVICE_ASSISTANCE: '/secure/service/move/assist',
  MOVE_SERVICE_CONFIRM: '/secure/service/move/confirm',
  MOVE_SERVICE_CUSTOMER_INFO: '/secure/service/move/info',
  MOVE_SERVICE_CO_CUSTOMER_INFO: '/secure/service/move/cocustomer',
  MOVE_SERVICE_MAIN_CUSTOMER_INFO: '/secure/service/move/maincustomer',
  MOVE_SERVICE_LEGACY_URL: `${config.legacySiteURL}/MoveService/Auth`,
  MOVE_SERVICE_OPTIONS: '/secure/service/move/options',
  MOVE_SERVICE_REVIEW: '/secure/service/move/review',
  MOVE_SERVICE_INELIGIBLE: '/secure/service/move/ineligible',
  MOVE_SERVICE: '/secure/service/move',
  MOVE_SERVICE_ADDRESS_INELIGIBLE: '/secure/service/move/address-ineligible',
  MOVE_SERVICE_ADDRESS_COMMERICAL_INELIGIBLE:
    '/secure/service/move/address-commercial-ineligible',
  MULTI_PAY_COMPLETE: '/secure/payment/one-check-payment/complete',
  MULTI_PAY_PAYMENT_AMOUNTS:
    '/secure/payment/one-check-payment/payment-amounts',
  MULTI_PAY_PAYMENT_METHOD: '/secure/payment/one-check-payment/review',
  MULTI_PAY_REVIEW: '/secure/payment/one-check-payment/download-print',
  MULTI_PAY_PAYMENT_REVIEW: '/secure/payment/one-check-payment/payment-review',
  MULTI_PAY_PAYMENT_COMPLETE:
    '/secure/payment/one-check-payment/payment-complete',
  MULTI_PAY: '/secure/payment/one-check-payment',
  MY_PROGRAMS: '/secure/my-programs',
  ADDITIONAL_PROGRAMS_AND_OFFERING: '/secure/additional-programs',
  NEED_MORE_TIME_TO_PAY_CONFIRM: '/secure/need-more-time-to-pay/confirm',
  NEED_MORE_TIME_TO_PAY_CONFIRM_NOTPA:
    '/secure/need-more-time-to-pay/confirm/ntpa',
  NEED_MORE_TIME_TO_PAY_PLAN_OPTIONS: '/secure/need-more-time-to-pay/options',
  NEED_MORE_TIME_TO_PAY_PLAN_OPTIONS_NOTPA:
    '/secure/need-more-time-to-pay/options/ntpa',
  NEED_MORE_TIME_TO_PAY_SUCCESS: '/secure/need-more-time-to-pay/success',
  NEED_MORE_TIME_TO_PAY: '/secure/need-more-time-to-pay',
  TPA_CREATE_PLAN: '/secure/need-more-time-to-pay/tpa/create-plan',
  TPA_REVIEW_PLAN: '/secure/need-more-time-to-pay/tpa/review-plan',
  TPA_CONFIRM_PLAN: '/secure/need-more-time-to-pay/tpa/confirm-plan',
  TPA_MAKE_PAYMENT: '/secure/need-more-time-to-pay/tpa/make-payment',
  TPA_COMPLETE: '/secure/need-more-time-to-pay/tpa/complete',
  OPEN_ENROLLMENT_CONFIRM: '/secure/account/open-enrollment/confirm',
  OPEN_ENROLLMENT_CONTACT: '/secure/account/open-enrollment/contact',
  OPEN_ENROLLMENT_DONE: '/secure/account/open-enrollment/done',
  OPEN_ENROLLMENT_ELECTION: '/secure/account/open-enrollment/election',
  OPEN_ENROLLMENT_ELECTION_LEGACY_URL: `${config.bizWebLegacyURL}/OpenEnrollment/Auth`,
  OPEN_ENROLLMENT_PLAN: '/secure/account/open-enrollment/plan',
  OPEN_ENROLLMENT: '/secure/account/open-enrollment',
  OREGON_CONSUMER_PRIVACY_ACT: '/secure/oregon-consumer-privacy-act',
  OREGON_CONSUMER_PRIVACY_ACT_PUBLIC_FORM: '/ocpa-web-form',
  OUTAGES: '/outages',
  //'outages-mobile-app' was excluded from sitemap in onCreatePage function of gatesby-node.js
  //Path in EXCLUDED_PAGES_FROM_SITEMAP array must be updated incase the path changes in the future.
  OUTAGES_MOBILE_APP: '/outages-mobile-app',
  OUTAGES_COUNTY: '/outages/county/:countyName/:zipCode',
  OUTAGE_POWER_OUT: '/outages-safety/power-out',
  PAPERLESS_BILLING_DONE: '/secure/profile/paperless-bill/success',
  PAPERLESS_BILLING: '/secure/profile/paperless-bill',
  PAYMENT_CONFIRMATION: '/secure/payment/pay-bill/transaction-successful',
  PAYMENT_HISTORY: '/secure/payment/billing-payment-history',
  PAYMENT_OPTIONS: '/billing-payment-options',
  PAYMENT_VERIFICATION: '/secure/payment/pay-bill/verify-payment',
  PAYMENT: '/secure/payment/pay-bill',
  PEAKTIME_REBATES_HISTORY: '/secure/peak-time-rebates/history',
  PEAKTIME_REBATES: '/secure/peak-time-rebates',
  PEAKTIME_REBATES_GIVING_BACK:
    '/secure/programs/peak-time-rebates-giving-back',
  PEAKTIME_REBATES_GIVING_BACK_INELIGIBLE:
    '/secure/programs/peak-time-rebates-giving-back/ineligible',
  PEAKTIME_REBATES_GIVING_BACK_CONFIRMATION:
    '/secure/programs/peak-time-rebates-giving-back/transaction-successful',
  PEAKTIME_REBATES_GIVING_BACK_NOT_TO_DONATE_CONFIRM:
    '/secure/programs/peak-time-rebates-giving-back/elected-not-to-donate',
  PGE_MARKETPLACE_SMART_THERMOSTAT:
    '/save-money/save-money-home/smart-thermostat-program',
  PREFERRED_DUE_DAY_SUCCESS: '/secure/profile/select-due-date/success',
  PREFERRED_DUE_DAY: '/secure/profile/select-due-date',
  PRIVACY_POLICY: '/privacy-policy',
  PUBLIC_SAFETY_POWER_SHUTOFF: '/psps',
  REGISTRATION_BUSINESS_FORM_ACCOUNTANT: '/register/business/accountant',
  REGISTRATION_BUSINESS_FORM: '/register/business/form',
  REGISTRATION_COMPLETE: '/register/success',
  REGISTRATION_CREATE_BUSINESS: '/register/business',
  REGISTRATION_CREATE_RESIDENTIAL: '/register/residential',
  REGISTRATION_SPECIAL_HANDLING: '/register/action-needed',
  REGISTRATION: '/register',
  RENEWABLE_ENROLLMENT_NOTIFICATION:
    '/green-future-solar-enrollment-residential/',
  RENEWABLE_POWER_CONFIG: '/secure/account/renewable-power/setup',
  RENEWABLE_POWER_CONFIRM: '/secure/account/renewable-power/confirm',
  RENEWABLE_POWER_DONE: '/secure/account/renewable-power/success',
  RENEWABLE_POWER_INDUSTRIAL: '/secure/account/renewable-power/industrial',
  RENEWABLE_POWER: '/secure/account/renewable-power',
  SIGN_IN: '/auth/sign-in',
  START_SERVICE_ASSISTANCE: '/service/start/assist',
  START_SERVICE_CONFIRM: '/service/start/confirm',
  START_SERVICE_CUSTOMER_INFO: '/service/start/info',
  START_SERVICE_CO_CUSTOMER_INFO: '/service/start/cocustomer',
  START_SERVICE_LEGACY_URL: `${config.legacySiteURL}/StartService/Auth`,
  START_SERVICE_OPTIONS: '/service/start/options',
  START_SERVICE_REVIEW: '/service/start/review',
  START_SERVICE: '/service/start',
  START_SERVICE_INELIGIBLE: '/service/start/ineligible',
  START_SERVICE_ADDRESS_INELIGIBLE: '/service/start/address-ineligible',
  START_SERVICE_ADDRESS_COMMERICAL_INELIGIBLE:
    '/service/start/address-commercial-ineligible',
  START_STOP_MOVE: '/start-stop-move',
  CONTACT_US: '/help/connect',
  HELP: '/help',
  STOP_SERVICE_ASSISTANCE: '/secure/service/stop/assist',
  STOP_SERVICE_CONFIRM: '/secure/service/stop/confirm',
  STOP_SERVICE_INELIGIBLE: '/secure/service/stop/ineligible',
  STOP_SERVICE_LEGACY_URL: `${config.legacySiteURL}/StopService/Auth`,
  GREEN_SOURCE: '/energy-choices/renewable-power/green-future-choice',
  HABITAT_SUPPORT: '/energy-choices/renewable-power/habitat-support',
  STOP_SERVICE_REVIEW: '/secure/service/stop/review',
  STOP_SERVICE: '/secure/service/stop',
  UNDERSTANDING_MY_BILL: '/help/help-topics/understanding-my-bill',
  UNDERSTANDING_MY_BILL_SPANISH: '/como-entender-mi-factura',
  UPDATE_INFORMATION: '/secure/profile/update-information',
  TWO_STEP_AUTHENTICATION: '/secure/profile/two-step-authentication',
  DOWNLOAD_MULTIPLE_BILLS: '/secure/download-multiple-bills',
  VIEW_BILL: '/secure/view-bill',
  WAYS_TO_SAVE: '/save-money/save-money-home',
  YOUR_RIGHTS_AND_RESPONSIBILITIES_PDF:
    'https://images.ctfassets.net/416ywc1laqmd/71rVZhBhOFDfB4l0Ivlnl4/d7c63e4ff1588cae112121dfe543f9d8/pge-customer-rights-responsibilities.pdf',
  HIGH_EFFICIENCY_PUMPS:
    '/save-money/save-money-home/more-ways/heating-cooling/high-efficiency-heat-pumps',
  BUSINESS_SERVICE_CHECKLIST: '/business-start-service-checklist',
  PTR_TERMS_AND_CONDITIONS: '/save-money/save-money-home/peak-time-rebates',
  PTR_PLANNING_PEAK_TIME_EVENTS:
    '/save-money/save-money-home/peak-time-rebates/planning-for-peak-time-events',
  REVIEW_SERVICE_MAP: '/about/who-we-are/service-area',
  QUICK_PAY: '/quick-pay',
  QUICK_PAY_SUCCESS: '/quick-pay/success',
  PGE_APP: '/pge-mobile-app',
  LEARN_MORE_ABOUT_NET_METERING: '/energy-choices/generate-power/net-metering',
  ROOFTOP_SOLAR: '/secure/rooftop-solar',
  TIMEOFDAY: '/secure/time-of-day',
  TIMEOFDAY_ENROLL: '/secure/time-of-day/enroll',
  TIMEOFDAY_INELIGIBLE: '/secure/time-of-day/ineligible',
  TIMEOFDAY_SPECIAL_HANDLING: '/secure/time-of-day/special-handling',
  TIMEOFDAY_SUCCESS: '/secure/time-of-day/success',
  TIMEOFDAY_METER_EXCEPTION: '/secure/time-of-day/meter',
  TIMEOFDAY_MANAGE: '/secure/time-of-day/manage',
  TIMEOFDAY_PENDING: '/secure/time-of-day/pending',
  TIMEOFDAY_UNENROLL: '/secure/time-of-day/unenroll',
  TIMEOFDAY_UNENROLL_SUCCESS: '/secure/time-of-day/unenroll/success',
  MY_WAYS_TO_SAVE: '/secure/my-ways-to-save',
  MY_ENERGY_USE: '/secure/my-energy-use',
  HOME_ENERGY_ANALYSIS: '/secure/home-energy-analysis',
  MY_PRICING_PLAN: '/secure/my-pricing-plan',
  SUPPLY_AND_RENEWABLES: '/supply-and-renewables',
  BLOG_HOME: '/blog',
  BLOG_CATEGORY: '/blog/category/',
  UPDATE_PERSONAL_INFORMATION: '/secure/profile/update-personal-information',
  RECONNECT_SERVICE: '/secure/reconnect-service',
  RECONNECT_SERVICE_START: '/secure/reconnect-service/start',
  RECONNECT_SERVICE_PAYMENT: '/secure/reconnect-service/payment',
  RECONNECT_SERVICE_PAYMENT_VERIFICATION:
    '/secure/reconnect-service/payment/verification',
  RECONNECT_SERVICE_PAYMENT_CONFIRMATION:
    '/secure/reconnect-service/payment/confirmation',
  EV_REBATE_FORM: '/secure/programs/sgtb-ev-rebate',
  EV_REBATE_FORM_ENROLL: '/secure/programs/sgtb-ev-rebate/enroll',
  EV_REBATE_PAGE:
    '/energy-choices/electric-vehicles-charging/charging-your-ev/ev-charging-pilot-program-home',
  QUICK_VIEWBILL: '/quick-view-bill',
  SSM_HOME: '/ssm',
  SSM_START: '/ssm/start',
  SSM_START_INELIGIBLE: '/ssm/start/ineligible',
  SSM_START_ADDRESS: '/ssm/start/address',
  SSM_START_CONTACT: '/ssm/start/contact',
  SSM_START_VERIFY: '/ssm/start/verify',
  SSM_START_HOME: '/ssm/start/home',
  SSM_START_CONFIRM: '/ssm/start/confirm',
  SSM_START_SUCCESS: '/ssm/start/success',
  SSM_STOP: '/secure/ssm/stop',
  SSM_STOP_ACCOUNT: '/secure/ssm/stop/accounts',
  SSM_STOP_INELIGIBLE: '/secure/ssm/stop/ineligible',
  SSM_STOP_ADDRESS: '/secure/ssm/stop/address',
  SSM_STOP_REVIEW: '/secure/ssm/stop/review',
  SSM_STOP_SUCCESS: '/secure/ssm/stop/success',
  SSM_MOVE: '/secure/ssm/move',
  SSM_MOVE_INELIGIBLE: '/secure/ssm/move/ineligible',
  SSM_MOVE_CHOOSE_ACCOUNT: '/secure/ssm/move/choose-account',
  SSM_MOVE_ADDRESS: '/secure/ssm/move/address',
  SSM_MOVE_HOME: '/secure/ssm/move/home',
  SSM_MOVE_TRANSFER_PEOPLE_TO_SERVICE:
    '/secure/ssm/move/transfer-people-to-service',
  SSM_MOVE_STOP_CURRENT_SERVICE: '/secure/ssm/move/stop-current-service',
  SSM_MOVE_CONFIRM: '/secure/ssm/move/confirm',
  SSM_MOVE_SUCCESS: '/secure/ssm/move/success',
  SSM_POST_ENROLLMENT: '/secure/ssm/post-enrollment',
  SSM_POST_ENROLLMENT_PAYMENT_PREFERENCES:
    '/secure/ssm/post-enrollment/payment-preference',
  SSM_POST_ENROLLMENT_NOTIFICATIONS:
    '/secure/ssm/post-enrollment/notifications',
  SSM_POST_ENROLLMENT_ENROLL_IN_PROGRAMS:
    '/secure/ssm/post-enrollment/enroll-in-programs',
  SSM_POST_ENROLLMENT_ADD_CO_CUSTOMER:
    '/secure/ssm/post-enrollment/co-customer',
  ESS_ELECTION_WINDOW: '/secure/ess/election-window',
  ESS_ELECTION_WINDOW_CONTACT_INFO: '/secure/ess/election-window/contact-info',
  ESS_ELECTION_WINDOW_SELECT_PRICING_PLAN:
    '/secure/ess/election-window/select-pricing-plan',
  ESS_ELECTION_WINDOW_SUMMARY: '/secure/ess/election-window/summary',
  ESS_ELECTION_WINDOW_FINAL_SUMMARY:
    '/secure/ess/election-window/final-summary',
  ESS_ELECTION_WINDOW_CONFIRMATION: '/secure/ess/election-window/confirmation',
  ESS_DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE:
    '/secure/ess/historical-usage-release',
  ESS_DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE_SUMMARY:
    '/secure/ess/historical-usage-release/summary',
  ESS_DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE_CONFIRMATION:
    '/secure/ess/historical-usage-release/confirmation',
  SSM_POST_ENROLLMENT_ADD_CO_CUSTOMER_ASSISTANCE:
    '/secure/ssm/post-enrollment/co-customer/assist',
  ESS_ENERGY_CHOICES_URL: '/energy-choices/energy-choices-business',
  ESS_DIRECT_ACCESS_OPERATIONS:
    '/about/info/pricing-plans/market-based-pricing/direct-access-operations',
  ESS_MARKET_BASED_PRICING_FAQ:
    '/about/info/pricing-plans/market-based-pricing/market-based-pricing-faq',
  ABOUT_US_MARKET_BASED_PRICING:
    '/about/info/pricing-plans/market-based-pricing',
  ABOUT_US_TOD_PRICING: '/about/info/pricing-plans/time-of-day',
  QUICK_LOGIN_PASSWORD: '/auth/quick-login',
  /*PGE Plus Rebate Only */
  PGE_PLUS_REBATE_ONLY_HOME: '/secure/pge-plus/ev-charger/rebate-only',
  PGE_PLUS_REBATE_ONLY_CONFIRM_YOUR_INFORMATION:
    '/secure/pge-plus/ev-charger/rebate-only/confirm-your-information',
  PGE_PLUS_REBATE_ONLY_CONFIRM_YOUR_CHARGER:
    '/secure/pge-plus/ev-charger/rebate-only/confirm-your-charger',
  PGE_PLUS_REBATE_ONLY_VERIFY_CHARGER_REBATE:
    '/secure/pge-plus/ev-charger/rebate-only/confirm-your-charger/verify-charger-rebate',
  PGE_PLUS_REBATE_ONLY_VERIFY_EVPULSE_REBATE:
    '/secure/pge-plus/ev-charger/rebate-only/confirm-your-charger/verify-evpulse-rebate',
  PGE_PLUS_REBATE_ONLY_UPLOAD_MODEL_NUMBER:
    '/secure/pge-plus/ev-charger/rebate-only/confirm-your-charger/upload-model-number',
  PGE_PLUS_REBATE_ONLY_UPLOAD_JUICEBOXID_NUMBER:
    '/secure/pge-plus/ev-charger/rebate-only/confirm-your-charger/upload-juiceboxId-number',
  PGE_PLUS_REBATE_ONLY_UPLOAD_SERIAL_NUMBER:
    '/secure/pge-plus/ev-charger/rebate-only/confirm-your-charger/upload-serial-number',
  PGE_PLUS_REBATE_ONLY_EVPULSE_SMART_CHARGING_ENROLLMENT:
    '/secure/pge-plus/ev-charger/rebate-only/confirm-your-charger/evPulse-smart-charging-enrollment',
  PGE_PLUS_REBATE_ONLY_CUSTOMER_INFORMATION:
    '/secure/pge-plus/ev-charger/rebate-only/customer-information',
  PGE_PLUS_REBATE_ONLY_INELIGIBLE:
    '/secure/pge-plus/ev-charger/rebate-only/ineligible',
  PGE_PLUS_REBATE_ONLY_EV_PULSE_SMART_CHARGING_ENROLLMENT:
    '/secure/pge-plus/ev-charger/rebate-only/ev-pulse-smart-charging-enrollment',
  PGE_PLUS_REBATE_ONLY_REBATE_ELIGIBILITY:
    '/secure/pge-plus/ev-charger/rebate-only/rebate-eligibility',
  PGE_PLUS_REBATE_ONLY_ELIGIBILITY_CONFIRMATION:
    '/secure/pge-plus/ev-charger/rebate-only/rebate-eligibility/confirmation',
  PGE_PLUS_REBATE_ONLY_CONNECT_TESLA:
    '/secure/pge-plus/ev-charger/rebate-only/rebate-eligibility/connect-tesla',
  PGE_PLUS_REBATE_ONLY_HIGHER_REBATES_CHECK:
    '/secure/pge-plus/ev-charger/rebate-only/rebate-eligibility/higher-rebates-check',
  PGE_PLUS_REBATE_ONLY_HIGHER_REBATES_ELIGIBILE:
    '/secure/pge-plus/ev-charger/rebate-only/rebate-eligibility/higher-rebates-eligible',
  PGE_PLUS_REBATE_ONLY_STANDARD_REBATES_ELIGIBILE:
    '/secure/pge-plus/ev-charger/rebate-only/rebate-eligibility/standard-rebates-eligible',
  PGE_PLUS_REBATE_ONLY_CHARGER_INFORMATION:
    '/secure/pge-plus/ev-charger/rebate-only/rebate-eligibility/charger-information',
  PGE_PLUS_REBATE_ONLY_CHARGER_INSTALLATION_COSTS:
    '/secure/pge-plus/ev-charger/rebate-only/rebate-eligibility/charger-installation-costs',
  PGE_PLUS_REBATE_ONLY_CONNECT_CHARGER:
    '/secure/pge-plus/ev-charger/rebate-only/rebate-eligibility/connect-charger',
  PGE_PLUS_REBATE_ONLY_EVPULSE_ELIGIBLE:
    '/secure/pge-plus/ev-charger/rebate-only/rebate-eligibility/evPulse-eligible',
  PGE_PLUS_REBATE_ONLY_CHARGER_INSTRUCTIONS:
    '/secure/pge-plus/ev-charger/rebate-only/rebate-eligibility/charger-instructions',
  PGE_PLUS_REBATE_ONLY_EV_PULSE_REBATE_RESULTS:
    '/secure/pge-plus/ev-charger/rebate-only/rebate-eligibility/evPulse/rebate-results',
  PGE_PLUS_REBATE_ONLY_REBATE_RESULTS:
    '/secure/pge-plus/ev-charger/rebate-only/rebate-eligibility/rebate-results',
  PGE_PLUS_REBATE_ONLY_SUCCESS:
    '/secure/pge-plus/ev-charger/rebate-only/success',
  PGE_PLUS_REBATE_ONLY_WELCOME_BACK:
    '/secure/pge-plus/ev-charger/rebate-only/welcome-back',

  /*PGE Plus Customer and Installation */
  PGE_PLUS_LANDING: '/pge-plus-static/',
  PGE_PLUS_EV_CHARGERS_HOME: '/secure/pge-plus-form/',
  PGE_PLUS_EV_CHARGERS_CHOOSE_ACCOUNT: '/secure/pge-plus-form/choose-account',
  PGE_PLUS_EV_CHARGERS_CONTACT_INFO: '/secure/pge-plus-form/contact-info',
  PGE_PLUS_EV_CHARGERS_CONFIRM_YOUR_CHARGER:
    '/secure/pge-plus-form/confirm-your-charger',
  PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY:
    '/secure/pge-plus-form/rebate-eligibility',
  PGE_PLUS_EV_CHARGERS_SELECT_CHARGER: '/secure/pge-plus-form/select-charger',
  PGE_PLUS_EV_CHARGERS_INSTALLATION: '/secure/pge-plus-form/installation',
  PGE_PLUS_EV_CHARGERS_INSTALLATION_LOCATION:
    '/secure/pge-plus-form/installation-location',
  PGE_PLUS_EV_CHARGERS_INSTALLATION_PANEL_DETAILS:
    '/secure/pge-plus-form/installation-panel-details',
  PGE_PLUS_EV_CHARGERS_INSTALLATION_PANEL_GARAGE:
    '/secure/pge-plus-form/installation-panel-garage',
  PGE_PLUS_EV_CHARGERS_INSTALLATION_PANEL_OUTSIDE_HOME:
    '/secure/pge-plus-form/installation-panel-outside',
  PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS: '/secure/pge-plus-form/upload-photos',
  PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS_CHARGER_RECIEPT:
    '/secure/pge-plus-form/upload-charger-receipt',
  PGE_PLUS_EV_CHARGERS_SUMMARY: '/secure/pge-plus-form/summary',
  PGE_PLUS_EV_CHARGERS_COST_ESTIMATION: '/secure/pge-plus-form/cost-estimation',
  PGE_PLUS_EV_CHARGERS_SUCCESS: '/secure/pge-plus-form/success',
  PGE_PLUS_EV_CHARGERS_INELIGIBLE: '/secure/pge-plus-form/ineligible',
  PGE_PLUS_EV_CHARGERS_WELCOME_BACK: '/secure/pge-plus-form/welcome-back',
  PGE_PLUS_EV_CHARGERS_FINAL_QUOTE: '/secure/pge-plus-form/final-quote',
  PGE_PLUS_EV_CHARGERS_VIEW_EV_QUOTE: '/secure/pge-plus-form/view-ev-quote',
  PGE_PLUS_EV_CHARGERS_VIEW_EV_APPLICATION_SUMMARY:
    '/secure/pge-plus-form/view-submitted-ev-application',
  PGE_PLUS_EV_CHARGERS_DECLINE: '/secure/pge-plus-form/decline',
  PGE_PLUS_EV_CHARGERS_FINAL_SUCCESS: '/secure/pge-plus-form/final-success',
  PGE_PLUS_GET_STARTED: '/secure/pge-plus/get-started',

  //Installer portal routes
  PGE_PLUS_INSTALLER_PORTAL_SIGN_IN: '/installer/auth/',
  PGE_PLUS_INSTALLER_PORTAL_FORGOT_PASSWORD: '/installer/forgot-password/',
  PGE_PLUS_INSTALLER_PORTAL_PROFILE: '/installer/secure/profile/',
  PGE_PLUS_INSTALLER_PORTAL_CHANGE_PASSWORD:
    '/installer/secure/profile/change-password/',
  PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING: '/installer/secure/jobs',
  PGE_PLUS_INSTALLER_PORTAL_JOB_DETAILS:
    '/installer/secure/jobs/:jobId/details',
  PGE_PLUS_INSTALLER_PORTAL_JOB_LEAD: '/installer/secure/jobs/:jobId',
  PGE_PLUS_INSTALLER_PORTAL_JOB_ACCEPTED:
    '/installer/secure/jobs/:jobId/job-accepted',
  PGE_PLUS_INSTALLER_PORTAL_JOB_DECLINED:
    '/installer/secure/jobs/:jobId/job-declined',
  PGE_PLUS_INSTALLER_PORTAL_JOB_CANCELLED:
    '/installer/secure/jobs/:jobId/job-cancelled',
  PGE_PLUS_INSTALLER_PORTAL_CONFIRMATION_DETAILS_AND_COST:
    '/installer/secure/jobs/:jobId/submit-final-cost',
  PGE_PLUS_INSTALLER_PORTAL_SUCCESS:
    '/installer/secure/jobs/:jobId/submit-final-cost-success',
  PGE_PLUS_INSTALLER_PORTAL_JOB_COMPLETED:
    '/installer/secure/jobs/:jobId/job-completed',
  PGE_PLUS_INSTALLER_PORTAL_VIEW_QUOTE:
    '/installer/secure/jobs/:jobId/view-quote',

  PGE_PLUS_INSTALLER_PORTAL_COMPLETE_QUOTE:
    '/installer/secure/jobs/:jobId/complete-quote',
  PGE_PLUS_INSTALLER_PORTAL_QUOTE_SUBMITTED:
    '/installer/secure/jobs/:jobId/complete-quote-success',
  PGE_PLUS_INSTALLER_PORTAL_REQUOTE: '/installer/secure/jobs/:jobId/re-quote',
  PGE_PLUS_INSTALLER_PORTAL_REQUOTE_SUCCESS:
    '/installer/secure/jobs/:jobId/re-quote-success',
  PGE_PLUS_INSTALLER_PORTAL_JOB_RESCHEDULE_OR_CANCEL:
    '/installer/secure/jobs/:jobId/reschedule-or-cancel',
  PGE_PLUS_INSTALLER_PORTAL_JOB_CANCEL: '/installer/secure/jobs/:jobId/cancel',
  PGE_PLUS_INSTALLER_PORTAL_JOB_SCHEDULE:
    '/installer/secure/jobs/:jobId/schedule',
  PGE_PLUS_INSTALLER_PORTAL_JOB_RESCHEDULE:
    '/installer/secure/jobs/:jobId/reschedule',
  PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_INELIGIBLE:
    '/secure/pge-plus-form/rebate-eligibility/ineligible',
  PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_NOREBATES:
    '/secure/pge-plus-form/rebate-eligibility/no-rebates',
  PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_NEW_CHARGER:
    '/secure/pge-plus-form/rebate-eligibility/new-charger',
  PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_CHARGER_REBATE:
    '/secure/pge-plus-form/rebate-eligibility/charger-rebate',
  PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_CHARGER_REBATE_VIN:
    '/secure/pge-plus-form/rebate-eligibility/charger-rebate/vin',
  PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_ALL_CLAIMED:
    '/secure/pge-plus-form/rebate-eligibility/all-claimed',
  PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_CONFIRMATION:
    '/secure/pge-plus-form/rebate-eligibility/confirmation',

  PGE_PLUS_INSTALLER_PORTAL_MANAGE_USER: '/installer/secure/manage-users',
  PGE_PLUS_INSTALLER_PORTAL_MANAGE_USER_ADD:
    '/installer/secure/manage-users/add',
  PGE_PLUS_INSTALLER_PORTAL_MANAGE_USER_EDIT:
    '/installer/secure/manage-users/edit/:userId',
  PGE_PLUS_PAY_OUT_OF_POCKET: '/secure/pge-plus/payment/:applicationId',
  /*   PGE_PLUS_PAY_OUT_OF_POCKET_PREFERENCE:
    '/secure/pge-plus/payment/:applicationId/preference', */
  PGE_PLUS_PAY_OUT_OF_POCKET_CONFIRM_BILLING_ADDRESS:
    '/secure/pge-plus/payment/:applicationId/billing-address',
  PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_INFO:
    '/secure/pge-plus/payment/:applicationId/payment-info',
  PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_VERIFY:
    '/secure/pge-plus/payment/:applicationId/payment-verify',
  PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_CONFIRMATION:
    '/secure/pge-plus/payment/:applicationId/payment-confirmation',
  PGE_PLUS_PAY_OUT_OF_POCKET_INELIGIBLE:
    '/secure/pge-plus/payment/:applicationId/ineligible',
  PGE_SMART_BATTERY: '/pge-smart-battery-pilot-residential-offering',
  SMART_CHARGING_PROGRAM:
    '/energy-choices/electric-vehicles-charging/charging-your-ev/charging-your-ev-at-home',
  IQBD_FORM: '/secure/iqbd/form',
  // PGE+ Consultation (Public)
  PGE_PLUS_CONSULTATION: '/pge-plus/consultation',
  PGE_PLUS_CONSULTATION_SELECT_ADDRESS: '/pge-plus/consultation/select-address',
  PGE_PLUS_CONSULTATION_DETAILS: '/pge-plus/consultation/details',
  PGE_PLUS_CONSULTATION_SUCCESS: '/pge-plus/consultation/success',

  // PGE+ Consultation (Secure)
  PGE_PLUS_SECURE_CONSULTATION: '/secure/pge-plus/consultation',
  PGE_PLUS_CONSULTATION_CHOOSE_ACCOUNT:
    '/secure/pge-plus/consultation/choose-account',
  PGE_PLUS_SECURE_CONSULTATION_DETAILS: '/secure/pge-plus/consultation/details',
  PGE_PLUS_SECURE_CONSULTATION_SUCCESS: '/secure/pge-plus/consultation/success',
});
