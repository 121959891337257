import React, { useState } from 'react';
// import React, { useContext, useState } from 'react';
import Close from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { AccountCircleOutlined, ExitToAppOutlined } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ShortcutsBar from './Shortcuts';
import colors from '../../themes/main-colors';
import ROUTES from '../../routes';
import { useTranslation } from '../../hooks/useTranslation';
import useAuth from '../../hooks/useAuth';
import { SearchBox, WithSearch } from '@elastic/react-search-ui';
import { navigate } from 'gatsby';
import SearchIcon from '@material-ui/icons/Search';
import MobileLanguageSelect from './MobileLanguageSelect';
import { Button } from '@material-ui/core';
import SignInModal from '../sign-in-form/SignInModal';
// import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controls: {
      display: 'inline-block',
      position: 'relative',
      padding: '0px 12px 0px 0px',
    },
    container: {
      backgroundColor: colors.noirBlur,
      display: 'flex',
      flexDirection: 'column',
    },
    closeButtonIcon: {
      color: colors.white,
    },
    buttonRoot: {
      background: 'none',
      color: colors.white,
      borderRadius: 'unset',
      fontSize: theme.spacing(2),
      fontWeight: 'bold',
    },
    elasticSearchBox: {
      boxSizing: 'border-box',
      padding: theme.spacing(0, 1, 1.25, 2),
      display: 'flex',
      '& .sui-search-box': {
        padding: theme.spacing(0, 1.25, 0, 0),
      },
      '& .sui-search-box__submit': {
        display: 'none',
      },
      '& .sui-search-box__text-input': {
        padding: theme.spacing(1),
      },
      '& > form': {
        width: '100%',
      },
      '& .MuiSvgIcon-root': {
        margin: theme.spacing(1.375, 0, 0, -5),
        zIndex: 1,
        fill: colors.noirBlur,
        height: theme.spacing(2),
      },
      '& .MuiButton-outlinedPrimary': {
        marginLeft: theme.spacing(2),
      },
      '& .sui-search-box__autocomplete-container': {
        top: '97%',
      },
    },
    searchInputField: {
      borderRadius: 0,
      backgroundColor: colors.inputFieldBackground,
    },
  }),
);

type MobileNavigationControlsProps = {
  toggleMenu: () => void;
  navigateToRoute: (route: string) => (e: any) => Promise<any>;
  closeModal?: () => void;
};

const MobileNavigationControls = ({
  toggleMenu,
  navigateToRoute,
  closeModal,
}: MobileNavigationControlsProps) => {
  const classes = useStyles();
  // const { isOutageMode } = useContext(featureFlagsContext);
  // const redirectUrl = isOutageMode ? ROUTES.OUTAGES : ROUTES.ACCOUNT;
  const { t } = useTranslation();
  const { signOut, isAuthenticated } = useAuth();
  const isBrowser = !(typeof window === 'undefined');
  const [isLoginModalVisible, setIsLoginModalVisible] = useState<boolean>(
    false,
  );

  return (
    <>
      <div className={classes.container}>
        <div className={classes.controls}>
          <Box display="flex" width="100%" justifyContent="space-between">
            <IconButton onClick={() => toggleMenu()}>
              <Close className={classes.closeButtonIcon} />
            </IconButton>
            <Button
              classes={{
                root: classes.buttonRoot,
              }}
              endIcon={
                isAuthenticated ? (
                  <ExitToAppOutlined />
                ) : (
                  <AccountCircleOutlined />
                )
              }
              onClick={
                isAuthenticated
                  ? () => signOut()
                  : () => setIsLoginModalVisible(true)
              }
            >
              {isAuthenticated ? t('SIGN_OUT') : t('SIGN_IN')}
            </Button>
          </Box>
        </div>

        <div className={classes.elasticSearchBox}>
          {isBrowser && (
            <WithSearch
              mapContextToProps={({ searchTerm, setSearchTerm }: any) => ({
                searchTerm,
                setSearchTerm,
              })}
            >
              {({ searchTerm, setSearchTerm }: any) => {
                return (
                  <>
                    <SearchBox
                      autocompleteMinimumCharacters={3}
                      autocompleteResults={{
                        sectionTitle: 'Results',
                        titleField: 'title',
                        urlField: 'url',
                        shouldTrackClickThrough: true,
                        clickThroughTags: ['test'],
                      }}
                      autocompleteSuggestions={true}
                      debounceLength={0}
                      onSubmit={async (value: any) => {
                        toggleMenu();
                        await navigate('/search?q=' + value);
                        setSearchTerm(value);
                      }}
                      inputProps={{ className: classes.searchInputField }}
                    />
                    <SearchIcon
                      style={{
                        cursor: 'pointer',
                        height: 'unset',
                        position: 'relative',
                        top: '-5px',
                      }}
                      onClick={async (e: any) => {
                        toggleMenu();
                        await navigate('/search?q=' + searchTerm);
                        setSearchTerm(searchTerm);
                      }}
                    />
                  </>
                );
              }}
            </WithSearch>
          )}
        </div>
      </div>
      <MobileLanguageSelect />
      <ShortcutsBar navigateToRoute={navigateToRoute} />
      {!isAuthenticated && (
        <SignInModal
          isOpen={isLoginModalVisible}
          redirectUrl={ROUTES.ACCOUNT}
          // redirectUrl={redirectUrl}
          handleClose={() => setIsLoginModalVisible(false)}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default MobileNavigationControls;
